import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import "../styles/schedule.scss"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { GameBox } from "../components/layout/gameBar"
import CalendarIcon from "../components/svg/calendar"

const months = [null, "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export default ({ location }) => {
    const calendar = useStaticQuery(graphql`
    query ScheduleQuery {
      ...january
      ...february
      ...march
      ...april
      ...may
      ...june
      ...july
      ...august
      ...september
      ...october
      ...november
      ...december
      }
  `)

    const dateConverter = (isoDateTime) => {
        const datetime = new Date(isoDateTime)
        const twoDigitMaker = (num) => num > 9 ? num : "0" + num

        const day = twoDigitMaker(datetime.getDate())
        const month = twoDigitMaker(datetime.getMonth() + 1)
        const year = twoDigitMaker(datetime.getFullYear())

        return `${year}-${month}-${day}`
    }

    const [date, setDate] = useState(new Date())
    const [convertedDate, setConvertedDate] = useState(dateConverter(date))

    const allGames = Object.values(calendar).map(({ edges }) => edges).flat(1).map(({ node }) => node.childScheduleJson).map(month => Object.values(month)).flat(2).filter(day => day !== null)

    useEffect(() => {
        setConvertedDate(dateConverter(date))
    }, [date])

    return (
        <Layout location={location} cssPageName="schedule">
            <SEO title="Game Schedule" />
            <h1 className="title">Schedule</h1>
            <DatePicker
                selected={date}
                onChange={date => setDate(new Date(date))}
                customInput={<DatePickerButton />}
                closeOnScroll={true}
                withPortal
            />
            <section className="days-container">
                <h2 className="day-label">{months[convertedDate.substr(5, 2).replace(/^0+/, '')]} {convertedDate.substr(8, 2).replace(/^0+/, '')}</h2>
                <div className="games-flex">
                    {allGames.filter(game => game.datetime.substr(0, 10) === convertedDate).length > 0 ?
                        allGames.filter(game => game.datetime.substr(0, 10) === convertedDate).map((game, index) => (
                            <GameBox
                                key={index}
                                awayScore={game.awayScore}
                                awayTeam={game.awayTeam}
                                datetime={game.datetime}
                                homeScore={game.homeScore}
                                homeTeam={game.homeTeam}
                                inning={game.inning}
                                inningStatus={game.inningStatus}
                                status={game.status}
                                watchLink={game.watchLink}
                            />
                        )) : <p>No games scheduled for today!</p>}
                </div>
            </section>
        </Layout>
    )
}

const DatePickerButton = ({ value, onClick }) => (
    <button className="datepicker-button" onClick={onClick}>Select Date<CalendarIcon color={"white"} /></button>
)